export const sesBookingSteps = [
  {
    id: 0,
    title: 'Appointment',
    subtitle: 'booking details',
    subtitleWhenDone: 'on appointment',
    completed: false,
    previousBtnText: 'back',
    nextBtnText: 'Continue',
  },
  {
    id: 1,
    title: 'verification',
    subtitle: 'verify your mobile number',
    subtitleWhenDone: 'mobile verified',
    completed: false,
    previousBtnText: 'Back',
    nextBtnText: 'get OTP',
  },
];

export const reserveHomeTestDirveCookingSteps = [
  {
    id: 2,
    title: 'test drive',
    subtitle: '',
    subtitleWhenDone: 'test drive',
    completed: false,
    previousBtnText: 'Back',
    nextBtnText: 'Continue',
  },
  {
    id: 3,
    title: 'location step title',
    subtitle: '',
    subtitleWhenDone: 'location step title',
    completed: false,
    previousBtnText: 'Back',
    nextBtnText: 'Continue',
  },
  {
    id: 0,
    title: 'booking details',
    subtitle: '',
    subtitleWhenDone: 'Book Details',
    completed: false,
    previousBtnText: 'Back',
    nextBtnText: 'Continue',
  },
  {
    id: 1,
    title: 'verification',
    subtitle: '',
    subtitleWhenDone: 'verification',
    completed: false,
    previousBtnText: 'Back',
    nextBtnText: 'get OTP',
  },
  {
    id: 4,
    title: 'payment Step',
    subtitle: '',
    subtitleWhenDone: 'Payment',
    completed: false,
    previousBtnText: 'Back',
    nextBtnText: 'Continue',
  },
];

export const reserveShowRoomCookingSteps = [
  {
    id: 2,
    title: 'test drive',
    subtitle: '',
    subtitleWhenDone: 'test drive',
    completed: false,
    previousBtnText: 'Back',
    nextBtnText: 'Continue',
  },
  {
    id: 0,
    title: 'booking details',
    subtitle: '',
    subtitleWhenDone: 'Book Details',
    completed: false,
    previousBtnText: 'Back',
    nextBtnText: 'Continue',
  },
  {
    id: 1,
    title: 'verification',
    subtitle: '',
    subtitleWhenDone: 'verification',
    completed: false,
    previousBtnText: 'Back',
    nextBtnText: 'get OTP',
  },
  {
    id: 4,
    title: 'payment Step',
    subtitle: '',
    subtitleWhenDone: 'Payment',
    completed: false,
    previousBtnText: 'Back',
    nextBtnText: 'Continue',
  },
];
